<template>
  <div>
    <!-- 导航栏 -->
    <NavigationBar style="margin-bottom:20px" />

    <keep-alive :include="['HomeHeader', 'NavigationBar', 'FooterBar']">
      <router-view></router-view>

      <Body />
    </keep-alive>

    <FooterBar />
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import Body from '@/views/Home/Body.vue';

export default {
  name: 'HeaderPage',
  components: { NavigationBar, FooterBar, Body },
  data() {
    return {}
  }
}
</script>

<style scoped>
.center-content {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}
</style>