<template>
  <div class="container">
    <el-card class="box">
      <p class="title">新品"超保温坦克罐"即将发售</p>
      <!-- 产品参数 -->
      <div class="parameter" style="text-align: center;">
        <el-row :gutter="20">
          <el-col v-for="(param, i) in parameter" :key="i" :span="12">
            <!-- 名称和数值在同一行显示，用冒号和空格分隔 -->
            <p>{{ param.name }}: {{ param.value }}</p>
          </el-col>
        </el-row>
      </div>

      <div class="image-grid">
        <div v-for="(image, i) in equipment" :key="i" class="image-wrapper">
          <img :src="require(`/src/assets/img/product/new/${image.src}`)" alt="" @mouseover="zoomIn(i)"
            @mouseleave="zoomOut(i)">
          <p :class="{ 'blue-text': image.zoomed }">{{ image.description }}</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { alignTransition } from '@/mixin'

export default {
  name: 'ServiceRecord',
  mixins: [alignTransition],
  data() {
    return {
      parameter: [
        { name: '产品名称', value: '超保温坦克罐' },
        { name: '罐体重量', value: '34000kg-37860kg' },
        { name: '罐体尺寸', value: '5800*2450*2450mm' },
        { name: '罐内物料净重', value: '21100kg' },
        { name: '保温性能', value: '40°环境温度下，24小时物料温度变化±1°' },
        { name: '追溯定位', value: '物料温度，压力全追溯，射频记录状态。' }
      ],

      equipment: [
        { src: '1.png', description: '', zoomed: false },
        { src: '2.png', description: '', zoomed: false },
      ]
    }
  }
};
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;

  .box {
    margin-top: 30px
  }

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

/* 布局 */
.image-grid {
  display: grid;
  /* 每行两张图片 */
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* 图片样式 */
.image-wrapper {
  width: 540px;
  height: 400px;
  position: relative;
  margin: 15px;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }

  p {
    margin-top: 10px;
    text-align: center;
    font-size: 25px;
  }
}

/* 鼠标移动样式 */
.blue-text {
  color: blue;
  transition: color 0.3s ease;
}

.image-wrapper img:hover {
  transform: scale(1.08);
}
</style>