<template>
  <el-footer>
    <div style="background-color: #383B4C; position: relative; width: 100%; height: 200px;">
      <div class="footerLogo">
        <img src="@/assets/img/logo.jpg" alt="" width="150px" height="60px">
      </div>
      <div class="telphone" style="margin-top: -40px;">
        <i style="font-style: normal;font-size: 18px;">安徽圣斯顿智能科技有限公司</i>
        <br>
        <br>
        <i style="font-style: normal;font-size: 18px;">电话：19339717353</i>
        <i style="font-style: normal;font-size: 18px; margin-left: 50px;">邮箱：19339717353@163.com</i>
        <br>
        <br>
        <br>
        <a href="https://beian.miit.gov.cn" style="color: white; text-decoration: none;"
          target="_blank">备案号：皖ICP备2024055244号</a>
      </div>
    </div>
  </el-footer>
</template>

<script>


export default {
  name: 'FooterBar',
}
</script>

<style scoped>
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-bottom: 141px;
}

.footerLogo {
  border-right: 1px solid #fff;
  position: absolute;
  top: 40px;
  padding: 40px;
}

.telphone,
.footerLogo {
  float: left;
  width: 500px;
  height: 50px;
  margin-left: 20px;
  text-align: center;
  color: white;
}

.telphone {
  margin-left: 110px;
  text-align: left;
  position: absolute;
  top: 80px;
  left: 550px;
}

::v-deep([data-v-4fd998ea] .el-header[data-v-4fd998ea], .el-footer[data-v-4fd998ea][data-v-4fd998ea]) {
  background-color: rgb(245, 245, 246);
}
</style>