<template>
  <div class="container">
    <el-card class="box" style="margin-bottom: 30px">
      <p class="title">严格的生产标准</p>
      <img style="width: 100%; margin: 10px auto;" src="@/assets/img/service/13.png" alt="">
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;

  .box {
    margin-top: 30px
  }

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}
</style>