<template>
  <div class="container">
    <el-card>
      <p class="title">企业位置</p>
      <span class="el-icon-map-location icon-map"></span>
      <i style="font-size:18px;margin-left: 10px;font-style: normal;">安徽圣斯顿智能科技有限公司</i>
      <!-- <TheMap/>  -->
      <br><br><br>
      <baidu-map class="baidu-map" bm-view="none" :center="{ lng: 119.4780, lat: 30.9086 }" :zoom="18">
      </baidu-map>
    </el-card>
  </div>
</template>

<script>
import { AboutAnimation } from "@/mixin"

export default {
  name: 'UsMap',
  mixins: [AboutAnimation],
  // components:{TheMap}
}
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;
  margin-top: 30px;

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

/* 图标 */
.icon-map {
  font-size: 45px;
  margin-left: 40px;
  margin-top: 20px;
  color: black
}

.baidu-map {
  height: 400px;
  width: 1000px;
  margin: auto;
  border: 1px solid rgb(171, 171, 171);
  margin-bottom: 20px;
}

.map {
  position: relative;
  left: 5%;
  width: 80%;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  /* color: rgb(47, 80, 177); */
  color: rgb(98, 98, 98);
  margin-bottom: 30px;
}

.map i:hover {
  /* color: rgb(9, 68, 248); */
  color: black;
}

/* .map span:hover{
        color: aquamarine;
    } */
</style>