<template>
  <div class="container">
    <el-card>
      <p class="title">荣誉奖项</p>
      <div class="image-grid">
        <div v-for="(image, i) in images" :key="i" class="image-wrapper">
          <img :src="require(`/src/assets/img/honor/${image.src}`)" alt="" @mouseover="zoomIn(i)"
            @mouseleave="zoomOut(i)">
          <p :class="{ 'blue-text': image.zoomed }">{{ image.description }}</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { alignTransition } from '@/mixin'

export default {
  name: 'CompanyIntroduce',
  mixins: [alignTransition],
  data() {
    return {
      images: [
        { src: '(10).png', description: '高薪技术企业', zoomed: false },
        { src: '(11).png', description: '守合同重信用企业', zoomed: false },
        { src: '(12).png', description: '2021销售额超1亿元企业', zoomed: false },
        { src: '(13).png', description: '2022销售额超1亿元企业', zoomed: false },
        { src: '(14).png', description: '2022纳税十强企业', zoomed: false },
        { src: '(15).png', description: '2022纳税超1000万元企业', zoomed: false }
      ]
    }
  },

  methods: {
    zoomIn(imageIndex) {
      this.images[imageIndex].zoomed = true;
    },
    zoomOut(imageIndex) {
      this.images[imageIndex].zoomed = false;
    }
  }
}
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;
  margin-top: 30px;

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

/* 布局 */
.image-grid {
  display: grid;
  /* 每行两张图片 */
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* 图片样式 */
.image-wrapper {
  width: 540px;
  height: 350px;
  position: relative;
  margin: 15px;
  margin-bottom: 50px;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }

  p {
    margin-top: 10px;
    text-align: center;
    font-size: 25px;
  }
}

/* 鼠标移动样式 */
.blue-text {
  color: blue;
  transition: color 0.3s ease;
}

img:hover {
  transform: scale(1.06);
}
</style>