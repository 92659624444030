<template>
  <div class="container">
    <el-card class="box">
      <p class="title">企业简介</p>
      <p style="font-size: 25px; width: 90%; margin: 20px auto;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;安徽圣斯顿智能科技有限公司是一家专业从事文化娱乐成套设备、智能户外整体厨房从研发、生产、销售、进出口业务为一体的企业，于2021年落户安徽省宣城市广德经济开发区国华路27号，为嘉兴圣斯顿金属制品有限公司（国家高新技术企业）全资投资；公司创立于2008年，位于嘉兴市：致力于研发制造，拥有全配套自主知识产权户外烧烤炉；生产高品质的燃气烤炉和户外厨房用品配件：燃气烤炉、边灶、橱柜、水槽等。
        我司主要出口：美国、欧洲和东南亚；以及国内、外定制产品 OCE和ODM订单系列，注册资金8000万元，现有员工180余人，占地面积108亩，建筑面积50000m²。
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司坚持以人为本，用于创新企业种子、秉持质量第一、用户至上的经营原则，保证在产品质量的同时，加大研发投入、技术创新、不断提升产品质量、改善产品性能，以产品技术研发为动力、产品质量“0”缺陷为基石，推动企业稳定发展。
      </p>
    </el-card>
  </div>
</template>

<script>
import { alignTransition } from '@/mixin'

export default {
  name: 'CompanyIntroduce',
}
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;
  margin-top: 30px;

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}
</style>