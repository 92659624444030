<template>
  <div class="container">
    <el-card>
      <p class="title">新闻中心</p>
      <div class="text" v-for="(item, i) in news" :key="i">
        <el-link :underline="false" style="font-size:20px;">
          <span>{{ item.title }}</span>
          <i style="color: red;">{{ item.time }}</i>
          <hr>
          <p>{{ item.content }}</p>
        </el-link>
      </div>
    </el-card>
  </div>
</template>

<script>
import { AboutAnimation } from "@/mixin"

export default {
  name: 'NewsCenter',
  mixins: [AboutAnimation],
  data() {
    return {
      news: [
        { title: '', time: '2015年', content: '产值达到4000万，纳税超过100万元。' },
        { title: '', time: '2017年', content: '产值达到1亿，纳税超过400万元。' },
        { title: '', time: '2019年', content: '产值达到1.5亿，浙江嘉兴新塍镇荣获镇政府纳税十强证书，及销售十强证书，高兴企业证书。' },
        { title: '', time: '2021年', content: '产值达到2亿，浙江嘉兴新塍镇荣获镇政府，纳税十强证书，及收入十强证书。' },
        { title: '', time: '2022年', content: '产值过亿，销售十强，纳税超1000万元，效益之星荣誉称号。' },
        { title: '', time: '2023年', content: '产值达到3.2亿。' }
      ]
    }
  },

}
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;
  margin-top: 30px;

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

.news {
  position: relative;
  left: 5%;
  width: 80%;
  height: 100%;
  overflow: hidden;
  padding: 30px;
}


.text .el-link i {
  width: 100%;
  float: left;
  margin-right: 20px;
  font-style: normal;
  font-size: 25px;
  font-weight: bolder;
}

::v-deep(.text .el-link) {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text p {
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 20px;
  text-indent: 2em;
}
</style>