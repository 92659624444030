<template>
  <div class="container">
    <!-- 使用 active-class 指定高亮时使用的类名 -->
    <router-link to="/product/ProductContent" class="nav-link" active-class="active">产品展示</router-link>
    <router-link to="/product/NewProduct" class="nav-link" active-class="active">新品预览</router-link>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style scoped>
.container {
  width: 1200px;
  margin: 30px auto -10px;
  text-align: center;
  border-bottom: 2px gainsboro solid;
}

.nav-link {
  font-size: 18px;
  display: inline-block;
  margin: 10px;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  background-color: #f2f2f2;
  border-radius: 5px;
  transition: background-color 0.3s;
}

/* 定义高亮时的样式 */
.nav-link.active {
  background-color: #FF7900;
  /* 高亮时的背景颜色 */
  color: white;
  /* 高亮时的文字颜色 */
}

.nav-link:hover {
  background-color: #FF7900;
}
</style>