<template>
  <div class="container">
    <el-card class="box">
      <p class="title">安全/目视化管理</p>
      <div class="image-grid">
        <div v-for="(image, i) in safe" :key="i" class="image-wrapper">
          <img :src="require(`/src/assets/img/service/${image.src}`)" alt="" @mouseover="zoomIn(i)"
            @mouseleave="zoomOut(i)">
          <p :class="{ 'blue-text': image.zoomed }">{{ image.description }}</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      safe: [
        { src: '7.png', description: '减版区', zoomed: false },
        { src: '8.png', description: '焊接区', zoomed: false },
        { src: '9.png', description: '折弯区', zoomed: false },
        { src: '10.png', description: '冲压区', zoomed: false },
        { src: '11.png', description: '原材料区', zoomed: false },
        { src: '12.png', description: '数车作业区', zoomed: false }
      ]
    };
  },
  methods: {
    zoomIn(imageIndex) {
      this.safe[imageIndex].zoomed = true;
    },
    zoomOut(imageIndex) {
      this.safe[imageIndex].zoomed = false;
    }
  }
};
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;

  .box {
    margin-top: 30px
  }

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

/* 布局 */
.image-grid {
  display: grid;
  /* 每行两张图片 */
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

/* 图片样式 */
.image-wrapper {
  width: 340px;
  height: 500px;
  position: relative;
  margin: 20px auto 50px;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }

  p {
    margin-top: 15px;
    text-align: center;
    font-size: 25px;
  }
}

/* 鼠标移动样式 */
.blue-text {
  color: blue;
  transition: color 0.3s ease;
}

.image-wrapper img:hover {
  transform: scale(1.08);
}
</style>