import VueRouter from "vue-router"

// 主页
import Home from "@/components/Home.vue"

// 关于我们
import AboutUs from '@/views/AboutUs/AboutUs';
import CompanyInfo from '@/views/AboutUs/Content/CompanyInfo.vue';
import Honor from '@/views/AboutUs/Content/Honor.vue';
import Map from '@/views/AboutUs/Content/Map.vue';
import News from '@/views/AboutUs/Content/News.vue';
import Phone from '@/views/AboutUs/Content/Phone.vue';

// 产品资源
import Product from '@/views/Product/Product.vue';
import ProductContent from "@/views/Product/Content/ProductContent.vue";
import NewProduct from "@/views/Product/Content/NewProduct.vue";

// 服务支持
import Service from '@/views/Service/Service.vue';
import Record from '@/views/Service/Content/Record.vue';
import Safe from '@/views/Service/Content/Safe.vue';
import Standard from '@/views/Service/Content/Standard.vue';
import Workshop from '@/views/Service/Content/Workshop.vue';
import Equipment from '@/views/Service/Content/Equipment.vue';

const router = new VueRouter({
  routes: [
    // 主页
    {
      path: '/homepage',
      component: Home,
      meta: { title: '圣斯顿', index: 1 }
    },
    // 关于我们
    {
      path: '/aboutus',
      component: AboutUs,
      meta: { title: '关于我们', index: 1 },
      children: [
        {
          path: '',
          redirect: 'CompanyInfo',
        },
        {
          path: 'CompanyInfo',
          name: 'CompanyInfo',
          component: CompanyInfo,
          meta: { title: '企业简介', index: 2 }
        },
        {
          path: 'Honor',
          name: 'Honor',
          component: Honor,
          meta: { title: '荣誉奖励', index: 2 }
        },
        {
          path: 'Phone',
          name: 'Phone',
          component: Phone,
          meta: { title: '联系方式', index: 2 }
        },
        {
          path: 'News',
          name: 'News',
          component: News,
          meta: { title: '新闻中心', index: 2 }
        },
        {
          path: 'Map',
          name: 'Map',
          component: Map,
          meta: { title: '企业位置', index: 2 }
        }
      ]
    },
    // 产品资源
    {
      path: '/product',
      component: Product,
      meta: { title: '产品资源', index: 1 },
      children: [
        {
          path: '',
          redirect: 'ProductContent',
        },
        {
          path: 'ProductContent',
          name: 'ProductContent',
          component: ProductContent,
          meta: { title: '产品展示', index: 2 }
        },
        {
          path: 'NewProduct',
          name: 'NewProduct',
          component: NewProduct,
          meta: { title: '新品预览', index: 2 }
        },
      ]
    },
    // 服务支持
    {
      path: '/service',
      component: Service,
      meta: { title: '服务支持', index: 1 },
      children: [
        {
          path: '',
          redirect: 'Record',
        },
        {
          path: 'Record',
          name: 'Record',
          component: Record,
          meta: { title: '生产记录', index: 2 }
        },
        {
          path: 'Safe',
          name: 'Safe',
          component: Safe,
          meta: { title: '安全管理', index: 2 }
        },
        {
          path: 'Standard',
          name: 'Standard',
          component: Standard,
          meta: { title: '生产标准', index: 2 }
        },
        {
          path: 'Workshop',
          name: 'Workshop',
          component: Workshop,
          meta: { title: '生产车间', index: 2 }
        },
        {
          path: 'Equipment',
          name: 'Equipment',
          component: Equipment,
          meta: { title: '生产设备', index: 2 }
        }
      ]
    },
  ],
  mode: 'history'
})

router.afterEach((to) => {
  document.title = to.meta.title || '圣斯顿'
})

export default router