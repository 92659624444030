import { render, staticRenderFns } from "./Standard.vue?vue&type=template&id=07882e81&scoped=true"
import script from "./Standard.vue?vue&type=script&lang=js"
export * from "./Standard.vue?vue&type=script&lang=js"
import style0 from "./Standard.vue?vue&type=style&index=0&id=07882e81&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.92.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07882e81",
  null
  
)

export default component.exports