<template>
  <div>
    <Header />

    <Breadcrumb />

    <Router />

    <!-- 使得路由页面可以展示出来 -->
    <router-view></router-view>

    <div style="margin-bottom: 30px;"></div>
  </div>
</template>

<script>
import { AboutAnimation } from '@/mixin';
import Header from './Header/index.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Router from './Header/Router.vue';

export default {
  name: 'Service',
  components: {
    Header,
    Router,
    Breadcrumb
  },
  mixins: [AboutAnimation]
}
</script>

<style scoped></style>