<template>
  <div class="container">
    <el-card class="box">
      <p class="title">生产车间</p>
      <p style="font-size: 25px; width: 90%; margin: 20px auto;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;圣斯顿总计设有12个专业生产车间，覆盖下料、加工、检验、组装等生产全链路。安徽、嘉兴两地工厂，协同年产能可达10亿元。
      </p>

      <img src="../../../assets/img/workshop/(1).png" alt="" style="width: 1150px; margin: 10px auto">
      <img src="../../../assets/img/workshop/(2).png" alt="" style="width: 1150px; margin: 10px auto">
    </el-card>

    <el-card class="box">
      <p class="title">环保喷塑流水线</p>
      <p style="font-size: 25px; width: 90%; margin: 20px; text-align: center; ">
        全自动大型喷涂线，可以实现大型物件均匀喷塑，物品最大提及可达4m*2m*1m。
      </p>
      <div class="image-grid">
        <div v-for="(image, i) in workshop1" :key="i" class="image-wrapper">
          <img :src="require(`/src/assets/img/workshop/${image.src}`)" alt="">
          <p :class="{ 'blue-text': image.zoomed }">{{ image.description }}</p>
        </div>
      </div>
    </el-card>

    <el-card class="box">
      <p class="title">专业仓储系统</p>
      <div class="image-grid">
        <div v-for="(image, i) in workshop2" :key="i" class="image-wrapper">
          <img :src="require(`/src/assets/img/workshop/${image.src}`)" alt="">
          <p :class="{ 'blue-text': image.zoomed }">{{ image.description }}</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { alignTransition } from '@/mixin'

export default {
  name: 'Workshop',
  mixins: [alignTransition],
  data() {
    return {
      workshop1: [
        { src: '(3).png', description: '', zoomed: false },
        { src: '(4).png', description: '', zoomed: false },
      ],
      workshop2: [
        { src: '(5).png', description: '', zoomed: false },
        { src: '(6).png', description: '', zoomed: false }
      ]
    }
  }
};
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;

  .box {
    margin-top: 30px
  }

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

/* 布局 */
.image-grid {
  display: grid;
  /* 每行两张图片 */
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* 图片样式 */
.image-wrapper {
  width: 540px;
  height: 400px;
  position: relative;
  margin: 15px;
  margin-top: 20px;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }

  p {
    margin-top: 10px;
    text-align: center;
    font-size: 25px;
  }
}

/* 鼠标移动样式 */
.blue-text {
  color: blue;
  transition: color 0.3s ease;
}

.image-wrapper img:hover {
  transform: scale(1.08);
}
</style>