<template>
  <div class="container">
    <el-card>
      <p class="title">联系方式</p>
      <div class="box">
        <div class="location">
          <span>公司名称</span> <br>
          <i>安徽圣斯顿智能科技有限公司</i>
        </div>
        <div class="phone">
          <span>电话</span> <br>
          <i>19339717353</i>
        </div>
        <div class="email">
          <span>邮箱</span> <br>
          <i>19339717353@163.com</i>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { alignTransition } from "@/mixin"

export default {
  name: 'ContactWay',
  mixins: [alignTransition]
}
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;
  margin-top: 30px;

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

.box {
  position: relative;
  left: 6.8%;
  margin: 20px;
}

.location,
.email,
.phone {
  width: 320px;
  height: 200px;
  color: rgb(47, 47, 47);
  text-align: center;
  float: left;
}

.location:hover,
.email:hover,
.phone:hover {
  background-color: skyblue;
  color: rgb(255, 255, 255);
}

.location span,
.email span,
.phone span {
  line-height: 100px;
  font-size: 20px;
  font-weight: bolder;
}

.location i,
.email i,
.phone i {
  font-size: 18px;
}
</style>