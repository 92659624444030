<template>
  <div class="container">
    <el-card class="box">
      <p class="title">生产设备</p>
      <p style="font-size: 25px; width: 90%; margin: 20px auto;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司累计投资生产设备 5,000
        万元，打造出一条领先于行业水平的专业生产线，包括大型激光、德国进口通快数控设备、机器人焊接设备、等先进的生产设备。
      </p>

      <div class="image-grid">
        <div v-for="(image, i) in equipment" :key="i" class="image-wrapper">
          <img :src="require(`/src/assets/img/device/${image.src}`)" alt="" @mouseover="zoomIn(i)"
            @mouseleave="zoomOut(i)">
          <p :class="{ 'blue-text': image.zoomed }">{{ image.description }}</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { alignTransition } from '@/mixin'

export default {
  name: 'ServiceRecord',
  mixins: [alignTransition],
  data() {
    return {
      equipment: [
        { src: '(5).png', description: '激光切割机', zoomed: false },
        { src: '(6).png', description: '通快激光加工机床', zoomed: false },
        { src: '(7).png', description: '通快数控折弯机', zoomed: false },
        { src: '(8).png', description: '通快数控机床', zoomed: false },
        { src: '(9).png', description: '', zoomed: false },
        { src: '(1).png', description: '', zoomed: false }
      ]
    }
  },

  methods: {
    zoomIn(imageIndex) {
      this.equipment[imageIndex].zoomed = true;
    },
    zoomOut(imageIndex) {
      this.equipment[imageIndex].zoomed = false;
    }
  }
};
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;

  .box {
    margin-top: 30px
  }

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

/* 布局 */
.image-grid {
  margin-top: 30px;
  display: grid;
  /* 每行两张图片 */
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* 图片样式 */
.image-wrapper {
  width: 540px;
  height: 400px;
  position: relative;
  margin: 15px;
  margin-bottom: 50px;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }

  p {
    margin-top: 10px;
    text-align: center;
    font-size: 25px;
  }
}

/* 鼠标移动样式 */
.blue-text {
  color: blue;
  transition: color 0.3s ease;
}

.image-wrapper img:hover {
  transform: scale(1.08);
}
</style>