<template>
  <div class="container">
    <Swiper />

    <Breadcrumb />

    <Home />

  </div>
</template>

<script>
import Swiper from '@/components/Swiper.vue';
import Home from './Content/Home.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  name: 'Body',
  components: { Swiper, Home, Breadcrumb }
}
</script>

<style scoped></style>
