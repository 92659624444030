<template>
  <div>
    <!-- 轮播图 -->
    <el-carousel :interval="5000" arrow="always" height="600px">
      <el-carousel-item v-for="(i, index) in img" :key="index"></el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

export default {
  name: 'Swiper',
  components: {},
  data() {
    return {
      img: [
        '@/assets/img/swiper/1.png',
        '@/assets/img/swiper/2.png',
        '@/assets/img/swiper/3.png',
        '@/assets/img/swiper/6.png',
      ]
    }
  }
}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #d3dce6;
  background: url(@/assets/img/swiper/1.png) no-repeat 100% 80%;
  background-size: 100% 100%;
}

.el-carousel__item:nth-child(2n+1) {
  background: url(@/assets/img/swiper/2.png) no-repeat 100% 80%;
  background-size: 100% 100%;
  background-color: #d3dce6;
}

.el-carousel__item:nth-child(3n) {
  background: url(@/assets/img/swiper/3.png) no-repeat 100% 80%;
  background-size: 100% 100%;
  background-color: #d3dce6;
}


.el-carousel__item:nth-child(6n) {
  background: url(@/assets/img/swiper/6.png) no-repeat 100% 80%;
  background-size: 100% 100%;
  background-color: #d3dce6;
}

::v-deep(.el-carousel__indicators) {
  top: 550px;
}
</style>