<template>
  <div>
    <!-- 头部 -->
    <el-header>
      <div class="header">
        <!-- logo -->
        <div class="logo">
          <router-link to="/">
            <img src="@/assets/img/logo.jpg" alt="" width="120px;" height="50px">
          </router-link>
        </div>

        <!-- 右侧文字 -->
        <div class="navigation">
          <ul>
            <!-- 首页 -->
            <li>
              <router-link to="/" style="font-size:20px">
                <div class="hue" :class="active0">首页</div>
              </router-link>
            </li>

            <!-- 关于我们 -->
            <li>
              <el-dropdown @command="handleCommand" placement="top">
                <!-- 使用 router-link 包裹 el-dropdown，以便点击时可以跳转到主路由 -->
                <router-link to="/aboutus/CompanyInfo" class="dropdown-link" style="font-size:20px" exact>
                  <div class="hue" :class="active1">关于我们</div>
                </router-link>
                <!-- 二级菜单 -->
                <el-dropdown-menu slot="dropdown">
                  <!-- 子菜单使用 el-dropdown-item，点击时调用 handleCommand 方法传递 command -->
                  <!-- 添加图标时直接用 class，示例如下 -->
                  <!-- <el-dropdown-item command="CompanyInfo" class="el-icon-phone-outline">企业简介</el-dropdown-item> -->
                  <el-dropdown-item command="CompanyInfo">企业简介</el-dropdown-item>
                  <el-dropdown-item command="Honor">荣誉奖励</el-dropdown-item>
                  <el-dropdown-item command="Phone">联系方式</el-dropdown-item>
                  <el-dropdown-item command="News">新闻中心</el-dropdown-item>
                  <el-dropdown-item command="Map">企业位置</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>

            <!-- 产品资源 -->
            <li>
              <el-dropdown @command="handleCommand" placement="top">
                <router-link to="/product/ProductContent" class="dropdown-link" style="font-size:20px" exact>
                  <div class="hue" :class="active2">产品资源</div>
                </router-link>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="ProductContent">产品展示</el-dropdown-item>
                  <el-dropdown-item command="NewProduct">新品预览</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>

            <!-- 服务支持 -->
            <li>
              <el-dropdown @command="handleCommand" placement="top">
                <router-link to="/service/Record" class="dropdown-link" style="font-size:20px" exact>
                  <div class="hue" :class="active3">服务支持</div>
                </router-link>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="Record">生产记录</el-dropdown-item>
                  <el-dropdown-item command="Safe">安全管理</el-dropdown-item>
                  <el-dropdown-item command="Standard">生产标准</el-dropdown-item>
                  <el-dropdown-item command="Workshop">生产车间</el-dropdown-item>
                  <el-dropdown-item command="Equipment">生产设备</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>

          </ul>
        </div>
      </div>
    </el-header>
  </div>
</template>

<script>

export default {
  name: 'NavigationBar',
  data() {
    return {}
  },

  methods: {
    // 根据 command 的值来执行不同的路由跳转
    handleCommand(command) {
      if (command === 'CompanyInfo') {
        this.$router.push('/aboutus/CompanyInfo');
      } else if (command === 'Honor') {
        this.$router.push('/aboutus/Honor');
      } else if (command === 'Phone') {
        this.$router.push('/aboutus/Phone');
      } else if (command === 'News') {
        this.$router.push('/aboutus/News');
      } else if (command === 'Map') {
        this.$router.push('/aboutus/Map');
      } else if (command === 'Record') {
        this.$router.push('/service/Record');
      } else if (command === 'Safe') {
        this.$router.push('/service/Safe');
      } else if (command === 'Standard') {
        this.$router.push('/service/Standard');
      } else if (command === 'Workshop') {
        this.$router.push('/service/Workshop');
      } else if (command === 'Equipment') {
        this.$router.push('/service/Equipment');
      } else if (command === 'ProductContent') {
        this.$router.push('/product/ProductContent');
      } else if (command === 'NewProduct') {
        this.$router.push('/product/NewProduct');
      }
    }
  }
}
</script>

<style scoped>
.dropdown-link {
  cursor: pointer;
  /* 鼠标悬停时显示为手形光标 */
}

.el-header {
  background-color: #B3C0D1;
  margin: 0;
  padding: 0;
}

::v-deep(.el-card__body) {
  padding: 0;
}

.header {
  width: 100%;
  height: 80px;
  background-color: #b7c6d9;
  overflow: hidden;
}

/* logo */
.el-header .header .logo img {
  width: 120px;
  height: 50px;
  margin-top: 20px;
  margin-left: 150px;
  float: left;
}

::v-deep(.el-header[data-v-4fd998ea]) {
  text-align: left;
  background-color: #fff;
  background: rgba(R, G, B, A);
}

/* 导航栏 */
a {
  text-decoration: none;
}

.hue {
  color: #313131;
}

.navigation ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  float: right;
  margin-right: 80px;
  margin-top: 30px;
}

.navigation li {
  position: relative;
}

.navigation li {
  padding: 0 0 15px 35px;
}

.navigation div:hover,
.navigation .active,
.navigation .active:focus,
.navigation li:hover>span {
  color: #FF7900;
}

.dropdown li {
  list-style-type: none;
}

.dropdown li span {
  float: left;
  margin-right: 30px;
  margin-top: 20px;
  font-size: 15px;
  color: black;
  cursor: pointer;
}

.dropdown li span:hover {
  color: rgb(253, 131, 131);
}

.el-icon-arrow-down {
  font-size: 12px;
}

.navigation i {
  font-size: 25px;
}

.navigation i:hover {
  color: rgb(246, 103, 103);
  font-weight: bolder;
}

.header .navigation .product i {
  font-size: 20px;
  color: #313131;
  font-style: normal;
}

.header .navigation a {
  color: #313131;
}

.header .navigation a:hover {
  color: #FF7900;
}

.header .navigation a:active {
  color: #FF7900;
}

::v-deep(.el-dropdown-link) {
  cursor: pointer;
}
</style>