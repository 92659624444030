<template>
  <div class="container">
    <!-- 产品展示区 -->
    <!-- <div v-for="(image, i) in product" :key="i" class="companyInfoImg">
      <el-card class="box">
        <img :src="require(`/src/assets/img/product/${image.src}`)" alt="">
      </el-card>
    </div> -->

    <!-- 锂离子电解液专用保温100OL罐 -->
    <div class="companyInfoImg">
      <el-card class="box">
        <img src="../../../assets/img/product/1.png" alt="">
        <img src="../../../assets/img/product/2.png" alt="">
        <img src="../../../assets/img/product/3.png" alt="">
      </el-card>
    </div>

    <!-- 锂离子电解液专用1000L罐 -->
    <div class="companyInfoImg">
      <el-card class="box">
        <img src="../../../assets/img/product/4.png" alt="">
        <img src="../../../assets/img/product/5.png" alt="">
      </el-card>
    </div>

    <!-- 锂离子电解液专用200L罐 -->
    <div class="companyInfoImg">
      <el-card class="box">
        <img src="../../../assets/img/product/6.png" alt="">
        <img src="../../../assets/img/product/7.png" alt="">
      </el-card>
    </div>

    <!-- 六氟磷酸锂专用100OL罐 -->
    <div class="companyInfoImg">
      <el-card class="box">
        <img src="../../../assets/img/product/8.png" alt="">
      </el-card>
    </div>

    <!-- 六氟磷酸锂专用729L罐 -->
    <div class="companyInfoImg">
      <el-card class="box">
        <img src="../../../assets/img/product/9.png" alt="">
        <img src="../../../assets/img/product/10.png" alt="">
        <img src="../../../assets/img/product/11.png" alt="">
      </el-card>
    </div>

  </div>
</template>

<script>

export default {
  name: 'ProductNavigation',
  data() {
    return {
      // 产品展示
      product: [
        { src: '1.png' },
        { src: '2.png' },
        { src: '3.png' },
        { src: '4.png' },
        { src: '5.png' },
        { src: '6.png' },
        { src: '7.png' },
        { src: '8.png' },
        { src: '9.png' },
        { src: '10.png' },
        { src: '11.png' }
      ],
    }
  }
}
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;
  margin-top: 30px;

  .box {
    margin-top: 30px;
  }

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

/* 展示区域 */
.companyInfoImg img {
  width: 100%;
  margin: 10px auto;
}
</style>