<template>
  <div class="container">
    <!-- 企业简介 -->
    <el-card class="box">
      <p class="title">企业简介</p>
      <p style="font-size: 25px; width: 90%; margin: 20px auto;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;圣斯顿金属制品有限公司，成立于2008年，是浙江省首批高新企业之一。
        公司总部位于浙江嘉兴，并在安徽广德设有占地72,000㎡的生产研发基地。嘉兴、广德两地，可协同实现高达10亿元的年产能。
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;十余年间，圣斯顿一直以高于行业的自我要求，不断深耕钣金制造技术 ，提升产品品质，致力于为客户提供高品质的气烤炉BBQ、户外厨房用品、
        取暖炉以及娱乐设备箱体、LED显示屏等各类金属制品。也可根据客户需求、来样或图纸，在充分尊重知识产权的前提下，提供各类金属制品的代工、定制生产服务。
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;凭借十年如一日的高品质产品、具有竞争力的价格和准时的货品交付，与诸多国内外客户建立了长期、稳定的合作关系，赢得了诸多信任和赞誉。
      </p>
      <div v-for="(image, i) in companyInfo" :key="i" class="companyInfoImg">
        <img :src="require(`/src/assets/img/company/${image.src}`)" alt="">
      </div>
    </el-card>

    <!-- 企业展示 -->
    <el-card class="box">
      <p class="title">企业展示</p>
      <div class="image-grid">
        <div v-for="(image, i) in images" :key="i" class="image-wrapper">
          <img :src="require(`/src/assets/img/home/${image.src}`)" alt="" @mouseover="zoomIn(i)"
            @mouseleave="zoomOut(i)">
          <p :class="{ 'blue-text': image.zoomed }">{{ image.description }}</p>
        </div>
      </div>
    </el-card>

    <!-- 产品展示 -->
    <el-card class="box">
      <p class="title">产品展示</p>
      <div class="image-grid">
        <div v-for="(image, i) in product" :key="i" class="image-wrapper">
          <img :src="require(`/src/assets/img/product/${image.src}`)" alt="" @mouseover="zoomIn1(i)"
            @mouseleave="zoomOut1(i)">
          <p :class="{ 'blue-text': image.zoomed }">{{ image.description }}</p>
        </div>
      </div>
    </el-card>

    <div style="margin-top: 30px;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 公司简介图片
      companyInfo: [
        { src: 'info1.png', },
        { src: 'info2.png' },
        { src: 'info3.png' },
        { src: 'info4.png' },
      ],
      // 底部八张图片
      images: [
        { src: '1.png', description: '办公楼外貌', zoomed: false },
        { src: '2.png', description: '工作环境', zoomed: false },
        { src: '3.png', description: '仓储中心', zoomed: false },
        { src: '4.png', description: '娱乐中心', zoomed: false },
        { src: '5.png', description: '1000通快激光复合机', zoomed: false },
        { src: '6.png', description: '通快折弯机5170', zoomed: false },
        { src: '7.png', description: '通快1000数冲机床', zoomed: false },
        { src: '8.png', description: '分板上料机', zoomed: false }
      ],
      // 产品展示
      product: [
        { src: 'homeproduct1.png', description: '气烤炉BBQ', zoomed: false },
        { src: 'homeproduct2.png', description: '户外厨房用品', zoomed: false },
        { src: 'homeproduct3.png', description: '风力发电', zoomed: false },
        { src: 'homeproduct4.png', description: '医疗配套设备', zoomed: false },
        { src: 'homeproduct5.png', description: '六氟磷酸锂专用1000L罐', zoomed: false },
        { src: 'homeproduct6.png', description: '离子电解液专用1000L罐', zoomed: false },
        { src: 'homeproduct7.png', description: '锂离子电解液专用保温1000L罐', zoomed: false },
        { src: 'homeproduct8.png', description: '六氟磷酸锂专用112L储罐', zoomed: false },
        { src: 'homeproduct9.png', description: '六氟磷酸锂专用792L储罐', zoomed: false },
        { src: 'homeproduct10.png', description: '锂离子电解液专用200L储罐', zoomed: false }
      ]
    };
  },

  methods: {
    zoomIn(imageIndex) {
      this.images[imageIndex].zoomed = true;
    },
    zoomOut(imageIndex) {
      this.images[imageIndex].zoomed = false;
    },

    zoomIn1(imageIndex) {
      this.product[imageIndex].zoomed = true;
    },
    zoomOut1(imageIndex) {
      this.product[imageIndex].zoomed = false;
    }
  }
};
</script>

<style scoped>
/* 主体框架 */
.container {
  width: 1200px;
  margin: auto;
  margin-top: 30px;

  .box {
    margin-top: 30px;
  }

  .title {
    text-align: center;
    border-bottom: 2px rgb(198, 201, 204) solid;
    font-size: 40px;
    margin: 0;
    font-weight: 700;
  }
}

/* 企业展示图片 start */
/* 布局 */
.image-grid {
  margin-top: 30px;
  display: grid;
  /* 每行两张图片 */
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* 图片样式 */
.image-wrapper {
  width: 540px;
  height: 400px;
  position: relative;
  margin: 15px;
  margin-bottom: 50px;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }

  p {
    margin-top: 10px;
    text-align: center;
    font-size: 25px;
  }
}

/* 鼠标移动样式 */
.blue-text {
  color: blue;
  transition: color 0.3s ease;
}

.image-wrapper img:hover {
  transform: scale(1.08);
}

/* 企业展示图片 end */

/* 公司简介的照片 */
.companyInfoImg img {
  width: 100%;
  margin: 10px auto;
}
</style>