<template>
  <div class="breadcrumb">
    <img class="iconfont-breadcrumb" src="@/assets/iconfont/breadcrumb.svg" alt="">
    <router-link to="/" style="color: gray;">首页</router-link>
    <span v-for="(crumb, index) in crumbs" :key="index">
      <span style="color: gray;"> > </span>
      <router-link style="color: gray;" v-if="crumb.to" :to="crumb.to">{{ crumb.name }}</router-link>
      <span v-else>{{ crumb.name }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  computed: {
    crumbs() {
      return this.$route.matched.reduce((acc, item) => {
        if (item.meta && item.meta.title) {
          acc.push({ name: item.meta.title, to: item.path });
        }
        return acc;
      }, []);
    }
  }
};
</script>

<style scoped>
.breadcrumb {
  width: 1200px;
  height: 25px;
  margin: auto;
  margin-bottom: -25px;
  padding: 10px 0;
  font-size: 18px;
}

.breadcrumb a {
  margin: 0 5px;
  color: #333;
  text-decoration: none;
}

/* .breadcrumb a:hover {
  text-decoration: underline;
} */

.iconfont-breadcrumb {
  width: 25px;
  margin: auto 10px -5px 10px;
}
</style>