import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=4819f485&scoped=true"
import script from "./Breadcrumb.vue?vue&type=script&lang=js"
export * from "./Breadcrumb.vue?vue&type=script&lang=js"
import style0 from "./Breadcrumb.vue?vue&type=style&index=0&id=4819f485&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.92.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4819f485",
  null
  
)

export default component.exports